import React, {useCallback, useEffect, useState} from "react";
import Slider from "react-slick";
import PrevArrow from "../common/PrevArrow";
import NextArrow from "../common/NextArrow";
import {Link, useNavigate } from 'react-router-dom';
import DefaultImage from "../../assets/image/noimage.jpg";

const BannerAlbum = ({albumList}) => {
    const sliderRef = React.useRef(null)
    useEffect(() => {
    }, [albumList]);
    const [slideIndex, setSlideIndex] = useState(0);
    const [moved, setMoved] = useState(false);

    const prevClick = useCallback(() => sliderRef.current.slickPrev(), []);
    const nextlick = useCallback(() => sliderRef.current.slickNext(), []);
    const navigate = useNavigate();

    const AlbumDetail = (albumCode, albumName) => {
        navigate('/albumDetail/' + albumCode, {state: {from : window.location.pathname, name: albumName}});
    }
    const basketAdd = (albumCode) => {
        //alert("장바구니:" + albumCode);
    }

    const imageErrorHandler = (e) => {
        e.target.src = DefaultImage
    }

    const settings = {
        // autoplay: true,
        // infinite: true,
        infinite: albumList.length > 2,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: albumList.length > 2,
        dots: false,
        responsive: [{
            "breakpoint": 1199,
            "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
            }
        }, {
            "breakpoint": 768,
            "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
            }
        }, {
            "breakpoint": 554,
            "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
            }
        }],
        prevArrow: <PrevArrow onClick={prevClick} />,
        nextArrow: <NextArrow onClick={nextlick} />
        // // 기타 옵션 설정
        //
        // customPaging: function (i) {
        //     return '<span></span>';
        // },
    };

    // 슬라이드 마우스 클릭
    const upListenner = (albumCode, albumName) => {
        if (moved) {
            //console.log(moved)
        } else {
            AlbumDetail(albumCode, albumName)
        }
    }
    const moveListener = () => {
        setMoved(true);
        console.log(moved)
    }
    const downListener = () => {
        setMoved(false);
        console.log(moved)
    }

    return(
        <>
            {albumList.length !== 0 && <section className="space-bottom-3">
                <div className="space-top-2 space-bottom-2 bg-gray-200">
                    <div className="container">
                        <header className="mb-5 d-md-flex justify-content-between align-items-center">
                            <h2 className="font-size-7 mb-3 mb-md-0">추천앨범</h2>
                            {/*<a href="#" className="h-primary d-block">더보기 <i className="glyph-icon flaticon-next"></i></a>*/}
                        </header>
                        <Slider ref={sliderRef} {...settings} className={"js-slick-carousel u-slick products border bg-white no-gutters"}>
                            {albumList.map((album) => {
                                return <div onMouseUp={() => upListenner(album.albumCode, album.albumName)}
                                            onMouseMove={moveListener}
                                            onMouseDown={downListener}
                                            key={album.albumCode}>
                                <div key={album.albumCode} className="product product__card border-right">
                                    <div className="media p-md-6 p-4 d-block d-md-flex" style={{cursor: "pointer"}}>
                                        <div className="woocommerce-loop-product__thumbnail mb-4 mb-md-0 mx-auto"
                                             style={{width:"125px",height:"125px",display:"flex",justifyContent:"center"}}>
                                            <Link  className="d-block">
                                                <img
                                                // width="125" height="125"
                                                style={{width:"100%",height:"100%",objectFit: "contain"}}
                                                src={album.thumbnailUrl}
                                                className="attachment-shop_catalog size-shop_catalog wp-post-image d-block mx-auto"
                                                alt="image-description"
                                                onError={imageErrorHandler}/></Link>
                                        </div>
                                        <div className="woocommerce-loop-product__body media-body ml-md-5d25">
                                            <div className="mb-3">
                                                <div className="text-uppercase font-size-1 mb-1 text-truncate"><Link
                                                    >{album.categoryName}</Link></div>
                                                <h2 className="woocommerce-loop-product__title font-size-3 text-lh-md mb-2 text-height-2 crop-text-2 h-dark">
                                                    <Link >{album.albumName}</Link></h2>
                                                <div className="font-size-2 text-gray-700 mb-1 text-truncate">
                                                    <Link
                                                       className="text-gray-700">{album.albumArtist}</Link>
                                                </div>
                                                <div className="price d-flex align-items-center font-weight-medium font-size-3">
                                                    <ins className="text-decoration-none mr-2"><span
                                                        className="woocommerce-Price-amount amount"><span
                                                        className="woocommerce-Price-currencySymbol"><span className={"font-size-2"}>앨범 전체 다운로드 </span>{album.albumPrice.toLocaleString()}원</span></span></ins>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            })}
                        </Slider>
                    </div>
                </div>
            </section>}
        </>
    )
}

export default BannerAlbum;