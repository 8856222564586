import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom'
import Slider from "react-slick"
import "./banner.css";

const frontEndUrl = process.env.REACT_APP_FRONTEND

const BannerTop = ({staticBanner, rollingBannerList}) => {
    const sliderRef = React.useRef(null)
    let navigate = useNavigate();
    useEffect(() => {
    }, []);
    const [slideIndex, setSlideIndex] = useState(0);
    const [dragging, setDragging] = useState(false)
    const settings = {
        // autoplay: true,
        infinite: true,
        // slidesToShow: 3,
        // slidesToScroll: 1,
        arrows: false,
        dots: true,
        dotsClass: 'js-pagination text-center u-slick__pagination position-absolute right-0 left-0 mb-n8 mb-lg-4 bottom-0',
        beforeChange: (prev, next) => {
            setSlideIndex(next);
            setDragging(true)
        },
        appendDots: dots => {
            return (
                <ul>
                    {dots.map((item, index) => {
                        return (
                            <li className={index === slideIndex ? "slick-active" : ""}
                                key={index}>{item.props.children}</li>
                        );
                    })}
                </ul>
            )
        },
        customPaging: index => {
            return (
                <span></span>
            )
        },
        afterChange: (prev, next) => {
            setDragging(false)
        }
    };

    function linkClickHandler(url, e) {
        if (dragging) {
            e.stopPropagation()
            return
        }
        if (url.includes(frontEndUrl)) {
            navigate(url.split(frontEndUrl)[1])
        } else {
            window.open(url)
        }
        e.preventDefault()
    }

    return (
        <section className={"space-bottom-3"}>
            <div className="space-2 space-lg-0">
                <div className="container">
                    <div className="d-flex banner-d-flex min-width-380 align-content-center">
                        {staticBanner && <div className="banner-w-25">
                            <div onClick={(e) => linkClickHandler(staticBanner.bannerLinkUrl, e)}
                                 className="bg-gray-200 min-height-350"
                                 style={{
                                     backgroundImage: `url(${staticBanner.bannerImageUrl})`,
                                     backgroundSize: 'cover',
                                     cursor: "pointer"
                                 }}>
                                {/*<div className="mb-2">*/}
                                {/*    <span className="font-weight-medium h6 text-gray-400">고정 배너</span>*/}
                                {/*</div>*/}
                                {/*<h6 className="font-weight-bold font-size-7 text-secondary-black-100">고정 배너</h6>*/}
                            </div>

                        </div>}
                        <div className="banner-w-75 banner-pl-4">
                            <Slider ref={sliderRef}
                                    {...settings}
                                    className="js-slick-carousel u-slick"
                                    data-pagi-classes="text-center u-slick__pagination position-absolute right-0 left-0 mb-n8 bottom-0">
                                {rollingBannerList.map((banner) => {
                                    if (banner) {
                                        return <><div key={banner.bannerType}
                                                    onClick={(e) => linkClickHandler(banner.bannerLinkUrl, e)}
                                                    className="js-slide bg-gray-200 min-height-350"
                                                    style={{
                                                        backgroundImage: `url(${banner.bannerImageUrl})`,
                                                        backgroundSize: 'cover',
                                                        cursor: 'pointer'
                                                    }}>
                                            {/*<div className="mb-2">*/}
                                            {/*    <span className="font-weight-medium h6 text-gray-400">롤링 배너</span>*/}
                                            {/*</div>*/}
                                            {/*<h6 className="font-weight-bold font-size-7">롤링 배너 #{banner.bannerType}</h6>*/}
                                            <div className="media-body mr-wd-4 align-self-center mb-4 mb-md-0">
                                                {/*<p*/}
                                                {/*    className="hero__pretitle text-uppercase font-weight-bold text-gray-400 mb-2"*/}
                                                {/*    data-scs-animation-in="fadeInUp" data-scs-animation-delay="200">The*/}
                                                {/*    Bookworm Editors'</p>*/}
                                                {/*<h2 className="hero__title font-size-14 mb-4"*/}
                                                {/*    data-scs-animation-in="fadeInUp"*/}
                                                {/*    data-scs-animation-delay="300">*/}
                                                {/*    <span className="hero__title-line-1 font-weight-regular d-block">Featured Books of the</span><span*/}
                                                {/*    className="hero__title-line-2 font-weight-bold d-block">February</span>*/}
                                                {/*</h2>*/}
                                                {/*<div className="mt-3">*/}
                                                {/*    <Link onClick={(e) => linkClickHandler(banner.bannerLinkUrl, e)} target="_blank"*/}
                                                {/*          rel="noopener noreferrer"*/}
                                                {/*          className="btn-lg btn-dark btn-wide rounded-0 hero__btn"*/}
                                                {/*          data-scs-animation-in="fadeInLeft"*/}
                                                {/*          data-scs-animation-delay="400">See*/}
                                                {/*        More</Link>*/}
                                                {/*</div>*/}
                                            </div>
                                            {/*<a href={banner.bannerUrlLink} target="_blank" rel="noopener noreferrer"*/}
                                            {/*   className="btn-lg btn-punch btn-wide rounded-0 hero__btn"*/}
                                            {/*   data-scs-animation-in="fadeInLeft" data-scs-animation-delay="400">See*/}
                                            {/*    More</a>*/}
                                        </div></>
                                    }
                                })}
                            </Slider>
                        </div>
                    </div>

                    {/*<Slider*/}
                    {/*    ref={sliderRef}*/}
                    {/*    {...settings}*/}
                    {/*    // beforeChange={handleBeforeChange}*/}
                    {/*    // afterChange={handleAfterChange}*/}
                    {/*    className="js-slick-carousel u-slick"*/}
                    {/*     data-pagi-classes="text-center u-slick__pagination position-absolute right-0 left-0 mb-n8 mb-lg-4 bottom-0">*/}
                    {/*    {rollingBannerList.map((banner) => {*/}
                    {/*        return <div className="js-slide" key={banner.bannerType}>*/}
                    {/*            <div className="hero row min-height-450 align-items-center">*/}
                    {/*                <div className="col-lg-7 col-wd-6 mb-4 mb-lg-0">*/}
                    {/*                    <div className="media-body mr-wd-4 align-self-center mb-4 mb-md-0">*/}
                    {/*                        <p className="hero__pretitle text-uppercase font-weight-bold text-gray-400 mb-2"*/}
                    {/*                           data-scs-animation-in="fadeInUp"*/}
                    {/*                           data-scs-animation-delay="200">The Bookworm Editors'</p>*/}
                    {/*                        <h2 className="hero__title font-size-14 mb-4"*/}
                    {/*                            data-scs-animation-in="fadeInUp"*/}
                    {/*                            data-scs-animation-delay="300">*/}
                    {/*                            <span className="hero__title-line-1 font-weight-regular d-block">Featured Books of the</span>*/}
                    {/*                            <span*/}
                    {/*                                className="hero__title-line-2 font-weight-bold d-block">February</span>*/}
                    {/*                        </h2>*/}
                    {/*                        <a href="www.naver.com" target="_blank" rel="noopener noreferrer"*/}
                    {/*                           className="btn btn-dark btn-wide rounded-0 hero__btn"*/}
                    {/*                           data-scs-animation-in="fadeInLeft"*/}
                    {/*                           data-scs-animation-delay="400">See More</a>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="col-lg-5 col-wd-6"*/}
                    {/*                     data-scs-animation-in="fadeInRight"*/}
                    {/*                     data-scs-animation-delay="500">*/}
                    {/*                    <img className="img-fluid" src="https://placehold.it/400x400"*/}
                    {/*                         alt="image-description"/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>;*/}


                    {/*    })}*/}


                    {/*</Slider>*/}
                </div>
            </div>
        </section>
    )

}

export default BannerTop;