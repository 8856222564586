import Pagination from "../../components/Pagination";
import React, {useState} from "react";
import SelectBox from "../common/SelectBox"
import {Link, useNavigate } from 'react-router-dom';
import DefaultImage from "../../assets/image/noimage.jpg"

const AlbumList = ({albumList, pageInfo, changeSort}) => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(1)
    const selectList = [
        {value: 1, label: '추천'},
        {value: 2, label: '등록순'},
        {value: 3, label: '발매순'},
        // {value: 4, label: '인기순'},
    ]

    const handleSelect = (e) => {
        setSelected(e.target.value)
        changeSort(e.target.value)
    }

    const AlbumDetail = (albumCode, albumName) => {
        navigate('/albumDetail/' + albumCode, {state: {from : window.location.pathname, name: albumName}});
    }

    const imageErrorHandler = (e) => {
        e.target.src = DefaultImage
    }

    return (
        <>
            <div className="container">
                <div id="primary" className="content-area order-2">

                    <div className="shop-control-bar d-lg-flex justify-content-between align-items-center mb-5 text-center text-md-left">
                        <div className="shop-control-bar__left mb-4 m-lg-0">
                            <p className="woocommerce-result-count m-0">총 <span>{pageInfo.itemCount}</span>개의 앨범</p>
                        </div>
                        <div className="shop-control-bar__right d-md-flex align-items-center">
                            <SelectBox selectList={selectList} selected={selected} handleSelect={handleSelect} isSearchBox={false}></SelectBox>
                            {/*<form className="woocommerce-ordering mb-4 m-md-0" method="get">*/}

                            {/*    <select*/}
                            {/*        ref={selectRef}*/}
                            {/*        className="js-select selectpicker dropdown-select orderby shadow-none outline-none py-2" name="orderby"*/}
                            {/*            data-style="border-bottom shadow-none outline-none py-2">*/}
                            {/*        {selectList.map((e) => {*/}
                            {/*            return <option key={e.value} value={e.value}>{e.label}</option>;*/}
                            {/*        })}*/}
                            {/*    </select>*/}
                            {/*</form>*/}
                            <ul className="nav nav-tab ml-lg-4 justify-content-center justify-content-md-start ml-md-auto" id="pills-tab" role="tablist">
                                <li className="nav-item border">
                                    <a className="nav-link p-0 height-38 width-38 justify-content-center d-flex align-items-center active" id="pills-one-example1-tab" data-toggle="pill" href="#pills-one-example1" role="tab" aria-controls="pills-one-example1" aria-selected="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width="17px" height="17px">
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M-0.000,0.000 L3.000,0.000 L3.000,3.000 L-0.000,3.000 L-0.000,0.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M7.000,0.000 L10.000,0.000 L10.000,3.000 L7.000,3.000 L7.000,0.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M14.000,0.000 L17.000,0.000 L17.000,3.000 L14.000,3.000 L14.000,0.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M-0.000,7.000 L3.000,7.000 L3.000,10.000 L-0.000,10.000 L-0.000,7.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M7.000,7.000 L10.000,7.000 L10.000,10.000 L7.000,10.000 L7.000,7.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M14.000,7.000 L17.000,7.000 L17.000,10.000 L14.000,10.000 L14.000,7.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M-0.000,14.000 L3.000,14.000 L3.000,17.000 L-0.000,17.000 L-0.000,14.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M7.000,14.000 L10.000,14.000 L10.000,17.000 L7.000,17.000 L7.000,14.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M14.000,14.000 L17.000,14.000 L17.000,17.000 L14.000,17.000 L14.000,14.000 Z" />
                                        </svg>
                                    </a>
                                </li>
                                <li className="nav-item border">
                                    <a className="nav-link p-0 height-38 width-38 justify-content-center d-flex align-items-center" id="pills-two-example1-tab" data-toggle="pill" href="#pills-two-example1" role="tab" aria-controls="pills-two-example1" aria-selected="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width="23px" height="17px">
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M-0.000,0.000 L3.000,0.000 L3.000,3.000 L-0.000,3.000 L-0.000,0.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M7.000,0.000 L23.000,0.000 L23.000,3.000 L7.000,3.000 L7.000,0.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M-0.000,7.000 L3.000,7.000 L3.000,10.000 L-0.000,10.000 L-0.000,7.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M7.000,7.000 L23.000,7.000 L23.000,10.000 L7.000,10.000 L7.000,7.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M-0.000,14.000 L3.000,14.000 L3.000,17.000 L-0.000,17.000 L-0.000,14.000 Z" />
                                            <path fillRule="evenodd" fill="rgb(25, 17, 11)" d="M7.000,14.000 L23.000,14.000 L23.000,17.000 L7.000,17.000 L7.000,14.000 Z" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-one-example1" role="tabpanel" aria-labelledby="pills-one-example1-tab">
                            <ul className="products list-unstyled row no-gutters row-cols-2 row-cols-lg-3 row-cols-wd-4 border-top border-left mb-6">
                                {albumList.map((album) => (
                                    <>
                                        <li key={album.albumCode} className="product col" style={{cursor: 'pointer'}}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                AlbumDetail(album.albumCode, album.albumName);
                                            }} >
                                            <div className="product__inner overflow-hidden p-3 p-md-4d875">
                                                <div
                                                    className="woocommerce-LoopProduct-link woocommerce-loop-product__link d-block position-relative">
                                                    <div className="woocommerce-loop-product__thumbnail mx-auto"
                                                         style={{width:"125px",height:"125px",display:"flex",justifyContent:"center"}}>
                                                        <Link className="d-block">
                                                            <img
                                                            // width="125" height="125"
                                                            style={{width:"100%",height:"100%",objectFit: "contain"}}
                                                            src = {album.thumbnailUrl ? album.thumbnailUrl : DefaultImage}
                                                            className="d-block mx-auto attachment-shop_catalog size-shop_catalog wp-post-image"
                                                            alt="image-description" onError={imageErrorHandler}/></Link>
                                                    </div>
                                                    <div className="woocommerce-loop-product__body product__body pt-3 bg-white">
                                                        <div className="text-uppercase font-size-1 mb-1 text-truncate">
                                                            <Link >{album.categoryName}</Link></div>
                                                        <h2 className="woocommerce-loop-product__title product__title h6 text-lh-md mb-1 text-height-2 crop-text-2 h-dark">
                                                            <Link >{album.albumName}</Link></h2>
                                                        <div className="font-size-2  mb-1 text-truncate">
                                                            <Link className="text-gray-700">{album.albumArtist}</Link></div>
                                                        <div
                                                            className="price d-flex align-items-center font-weight-medium font-size-3">
                                                        <span className="woocommerce-Price-amount amount"><span
                                                            className="woocommerce-Price-currencySymbol text-secondary-black-100"><span className={"font-size-2"}>앨범 전체 다운로드 </span>{album.albumPrice.toLocaleString()}원</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </>
                                ))}
                            </ul>
                        </div>
                        <div className="tab-pane fade" id="pills-two-example1" role="tabpanel"
                             aria-labelledby="pills-two-example1-tab">
                            <ul className="products list-unstyled mb-6">
                                {albumList.map((album) => (
                                    <>
                                    <li key={album.albumCode} className="product product__list" style={{cursor: 'pointer'}}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            AlbumDetail(album.albumCode);
                                        }}>
                                        <div className="product__inner overflow-hidden p-3 p-md-4d875">
                                            <div
                                                className="woocommerce-LoopProduct-link woocommerce-loop-product__link row position-relative">
                                                <div className="col-md-auto woocommerce-loop-product__thumbnail mb-3 mb-md-0"
                                                     style={{width:"125px",height:"125px",display:"flex"}}>
                                                    <Link  className="d-block">
                                                        <img
                                                        src={album.thumbnailUrl ? album.thumbnailUrl : DefaultImage}
                                                        style={{width:"100%",height:"100%",objectFit: "contain"}}
                                                        className="d-block mx-auto attachment-shop_catalog size-shop_catalog wp-post-image"
                                                        alt="image-description" onError={imageErrorHandler}/></Link>
                                                </div>
                                                <div
                                                    className="col-md woocommerce-loop-product__body product__body pt-3 bg-white mb-3 mb-md-0">
                                                    <div className="text-uppercase font-size-1 mb-1 text-truncate">
                                                        <Link >{album.categoryName}</Link></div>
                                                    <h2 className="woocommerce-loop-product__title product__title h6 text-lh-md mb-1 crop-text-2 h-dark">
                                                        <Link  tabIndex="0">{album.albumName}</Link></h2>
                                                    <div className="font-size-2  mb-2 text-truncate">
                                                        <Link className="text-gray-700">{album.albumArtist}</Link></div>

                                                    <div
                                                        className="price d-flex align-items-center font-weight-medium font-size-3">
                                                    <span className="woocommerce-Price-amount amount text-secondary-black-100"><span
                                                        className="woocommerce-Price-currencySymbol ">{album.albumPrice.toLocaleString()}</span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    </>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <Pagination
                        activePage={pageInfo.activePage}
                        items={pageInfo.itemsCountPerPage} // 페이지당 항목수
                        itemsCount={pageInfo.itemCount}
                        pageDisplay={pageInfo.pageDisplay}
                        onPageChange={pageInfo.onPageChange}
                    />

                </div>
            </div>
        </>
    )
}
export default AlbumList