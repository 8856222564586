// Body.js
import React, {useEffect} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from '../../pages/Home'
import SingleBoardPage from '../../pages/board/SingleBoardPage'
import InformationUsePage from '../../pages/board/InformationUsePage'
import CategoryPage from "../../pages/category/CategoryPage";
import MyPage from '../../pages/myPage/MyPage'
import NoticePage from "../../pages/board/NoticePage";
import SpecialPage from "../../pages/category/SpecialPage";
import SpecialDetail from "../category/SpecialDetail";
import BoardDetail from "../common/BoardDetail";
import SearchPage from "../category/SearchList";
import AuthJoin from "../user/AuthJoin";
import AlbumDetail from "../category/AlbumDetail";
import AuthPwd from "../user/AuthPwd";

const Body = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    useEffect(() => {
        window.scrollTo(0, 0);
        const gnbHeight = document.querySelector('#site-header').offsetHeight; // GNB 높이 가져오기
        const content = document.querySelector('#content');
        content.style.marginTop = `${gnbHeight}px`;
    }, [currentPath]);
    return (
        <>
            <main id="content">
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/auth-join" element={<AuthJoin/>} />
                    <Route path="/auth-pwd" element={<AuthPwd/>} />

                    <Route path="/best" element={<CategoryPage pageType='0'/>} />
                    <Route path="/new" element={<CategoryPage pageType='1'/>} />
                    <Route path="/song" element={<CategoryPage pageType='2'/>} />
                    <Route path="/popjazz" element={<CategoryPage pageType='3'/>} />
                    <Route path="/classic" element={<CategoryPage pageType='4'/>} />
                    <Route path="/special" element={<SpecialPage/>} />
                    <Route path="/specialDetail" element={<SpecialDetail />} />
                    <Route path="/mp3" element={<CategoryPage pageType='5' />} />

                    <Route path="/search" element={<SearchPage/>}/>
                    <Route path="/albumDetail/:albumCode" element={<AlbumDetail/>} />

                    <Route path="/myPage" element={<MyPage pageType={'myPage'} />} />
                    <Route path="/basket" element={<MyPage pageType={'basket'} />} />
                    <Route path="/payment/success" element={<MyPage pageType={'paymentSuccess'} />} />
                    <Route path="/payment/fail/:cause" element={<MyPage pageType={'paymentFail'}/>} />
                    <Route path="/basket/close-inicis" element={<MyPage pageType={'close-inicis'} />} />
                    <Route path="/myAlbum" element={<MyPage pageType={'myAlbum'} />} />

                    <Route path="/guide" element={<InformationUsePage pageType={'guide'} />} />
                    <Route path="/faq" element={<InformationUsePage pageType={'faq'} />} />
                    <Route path="/inquiry" element={<InformationUsePage pageType={'inquiry'} />} />
                    <Route path="/inquirySend" element={<InformationUsePage pageType={'inquirySend'} />} />

                    <Route path="/notice" element={<NoticePage dataType='4'/>} />
                    <Route path="/boardDetail" element={<BoardDetail/>}/>
                    <Route path="/aboutUs" element={<SingleBoardPage dataType='0'/>} />
                    <Route path="/usageTerms" element={<SingleBoardPage dataType='1'/>} />
                    <Route path="/privacyPolicy" element={<SingleBoardPage dataType='2'/>} />
                </Routes>

            </main>
        </>
    );
};

export default Body;
